
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

















































































































header {
	margin-bottom: $default_padding/2;
}

.input-group {
	display: flex;
	flex-flow: column nowrap;

	.input-group-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: 10px;

		input,
		.bool-input {
			margin: 0;
		}

		input,
		.button,
		.bool-input {
			margin-left: 10px;
		}

		.button {
			button {
				padding: 5px;
			}
		}
	}

	& > .button {
		align-self: center;
	}

	button {
		background-color: $color__dark;

		&:hover {
			background-color: rgba($color: $color__dark, $alpha: 0.8);
		}
	}
}
